import React from 'react';
import style from '../../styles/Footer.module.scss';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className={style.site_footer}>
      <div className="flex flex-col sm:flex-row pt-16 px-5 pb-10 justify-center sm:justify-evenly">
        <div>
          <h5>Contact Info</h5>
          <ul className='p-0'>
            <li className="flex items-center">
              <div className="bg-gray-600 rounded-full mr-5 p-2">
                <svg className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="lightgray" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  <path stroke="lightgray" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.8 13.938h-.011a7 7 0 1 0-11.464.144h-.016l.14.171c.1.127.2.251.3.371L12 21l5.13-6.248c.194-.209.374-.429.54-.659l.13-.155Z" />
                </svg>
              </div>
              <div>
                <p className='text-sm my-1'>Ecolotech ASL Incorporated</p>
                <p className='text-sm my-1'>PO Box 5733, Woodridge, IL 60517</p>
              </div>
            </li>
            <li className="flex items-center">
              <div className="bg-gray-600 rounded-full mr-5 p-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="lightgray" height={22}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                </svg>
              </div>
              sales@sludgeprobe.com
            </li>
          </ul>
        </div>
        <div>
          <h5>Navigation</h5>
          <ul className='p-0'>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/products">Products</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li>
              <a href="https://www.linkedin.com/company/ecolotech-asl-inc/" target='_blank' rel='noreferrer'>
                <svg className="w-6 h-6 text-gray-200 dark:text-white bg-gray-600 rounded" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fillRule="evenodd" d="M12.51 8.796v1.697a3.738 3.738 0 0 1 3.288-1.684c3.455 0 4.202 2.16 4.202 4.97V19.5h-3.2v-5.072c0-1.21-.244-2.766-2.128-2.766-1.827 0-2.139 1.317-2.139 2.676V19.5h-3.19V8.796h3.168ZM7.2 6.106a1.61 1.61 0 0 1-.988 1.483 1.595 1.595 0 0 1-1.743-.348A1.607 1.607 0 0 1 5.6 4.5a1.601 1.601 0 0 1 1.6 1.606Z" clipRule="evenodd" />
                  <path d="M7.2 8.809H4V19.5h3.2V8.809Z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={style.column_xs_12}>
        <p className="pb-4 text-center m-0 text-sm">© Copyright 2018 Sludgeprobe</p>
      </div>
    </footer>
  );
}

export default Footer
import React from 'react';
import style from '../styles/Contact.module.scss'
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

function Contact() {
  return (
  <div>
    <Navbar />

    <div className={style.maincontent}>
      <div className={style.section}> 
        <div className={style.textblock}>
          <h2>Contact Us</h2>
          <p>For more info about our product, or sales inquiries, please reach out to us.</p>
          <div className={style.contactdetails}>
            <p>630-352-9794</p>
            <p>sales@sludgeprobe.com</p>
            <div className={style.address}>
              <p>Ecolotech ASL Incorporated</p>
              <p>PO Box 5733</p>
              <p>Woodridge, IL 60517</p>
            </div>
          </div>  
        </div>

        <div>
          <img 
            className={style.bgimage}
            src={require("./images/contact-us.jpg")}
            alt="contact us illustration"
          />
        </div>
      </div>
    </div>

    <Footer />
    
  </div>
  )
}

export default Contact
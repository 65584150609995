import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import style from '../styles/Home.module.scss'
import AOS from 'aos';
import "aos/dist/aos.css";

const gallery = [
  "probe-and-reciever.jpg",
  "sludge-probe-photocell-detector.jpg",
  "sludge-probe-top-down-full-view.jpg"
]

function Home() {
  const [mainImage, setMainImage] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 800,
      disable: 'mobile'
    })
  }, []);

  return (
    <div>
      <Navbar />

      <div className={style.bannerimg}>
        <img src={require("./images/ecolotech-logo.png")} alt="ecolotech wave logo" />
  		</div>

      <div className={style.galleryfull}>
        <div className={style.galleryleft}>
          <div className={style.gallerytitle}>

          </div>
          <div className={style.gallery}>
            <img 
              src={require('./images/' + gallery[mainImage])} 
              className={style.mainimage}
              alt="device" 
            />

            <div className={style.minigallery}>
              <img 
                src={require("./images/" + gallery[0])}
                onMouseEnter={()=>setMainImage(0)} 
                className={style.minigallery}
                alt="sludge blanket level detector probe and receiver" 
              />
              <img 
                src={require("./images/" + gallery[1])}
                onMouseEnter={()=>setMainImage(1)} 
                className={style.minigallery}
                alt="sludge blanket level detector probe and receiver" 
              />
              <img 
                src={require("./images/" + gallery[2])}
                onMouseEnter={()=>setMainImage(2)} 
                className={style.minigallery}
                alt="sludge blanket level detector probe and receiver" 
              />
            </div>
          </div>
        </div>

        <div className={style.galleryright}>
            <h1>ARMORED SLUDGLITE</h1>
            <h2>Portable sludge blanket level detector</h2>
            <p>
              The Ecolotech Armored Sludglite is a highly accurate and portable sludge blanket level detector that is ideal for a wide range of applications. 
              Designed with corrosion-resistant stainless steel and triple-secured Neoprene cable, this detector is built to withstand harsh environments and provide precise readings wherever you need them.
            </p>
        </div>
      </div>

      <div className={style.divider}></div>

      <div className={style.section}>
        <div>
          <h2>Usage</h2>
            <p>As the sludge probe descends into liquid, the sensor responds immediately to changing density and transmits the effect to the audio receiver as a sound signal. When the signal diminishes to no sound at all, the sludge level has been reached. Depth markers on the cable indicate the feet of liquid above sludge blanket.</p>
        </div>
        <div>
          <h2>Specifications</h2>
          <ul>
            <li>Receiver and sludge probe are both constructed from corrosion resistant stainless steel</li>
            <li>Probe is completely o-ring sealed and is triple secured to the reinforced Neoprene cable</li>
            <li>Operates on a photo-optic sensing principal</li>
            <li>Produces a variable audio digital frequency output as the sludge level is reached</li>
            <li>Audio output receiver is completely solid state and is equipped with 30 feet of cord, a speaker, push button switch, 9-volt battery and nylon lanyard</li>
          </ul>
        </div>
        <div className={style.madein}>
          <h2><i></i> Made in the U.S.A.</h2>
          <div className={style.btncontainer}>
            <Link to="/contact" className={style.contactbtn}>
              Contact Us
            </Link>
          </div>
        </div>
      </div>

      <div className={style.banner}>
        <div className={style.bannerblocks}>
          <div className={style.bannerblock} data-aos="fade-up">
            <img
              src={require("./images/sludge-probe-photocell-detector.jpg")} 
              alt="close up of probe" 
              className={style.detailimg}
            />
            <div className={`${style.bannertext}`}>
              <h2>ENGINEERED</h2>
              <p>Precision design by our team of engineers</p>
            </div>
          </div>
          <div className={style.bannerblock} data-aos="fade-up">
              <img src={require("./images/sludge-probe-reciever.jpg")}  
                alt="close up of receiver with speaker and button" 
                className={style.detailimg}
              />
            <div className={`${style.bannertext}`}>
              <h2>ARMORED</h2>
              <p>Sludge probe and receiver are both constructed from durable stainless steel</p>
            </div>
          </div>
          <div className={style.bannerblock} data-aos="fade-up">
            <img src={require("./images/unit.jpg")} 
              alt="full view of receiver and probe" 
              className={style.detailimg} 
            />
            <div className={`${style.bannertext}`}>
              <h2>PORTABLE</h2>
              <p>Get accurate sludge level readings wherever you need them. Carrying cases available</p>
            </div>
          </div>
        </div>
  		</div>

      <div className={style.aboutsection}>
        <div className={style.colleft}>
          <h2>ABOUT US</h2>
          <p>With more than 40 years experience in all types of sludge blanket applications, the Sludglite has proven to be the most rugged and reliable sludge blanket level detector in the world.<br/><br/>
            The Sludglite is the result of many years in the industry listening and understanding the needs of the user.<br/>
            Our sludge level detector eliminates messy and repetitive sampling procedures.<br/>
            The Sludglite is a portable and easy-to-use device that accurately measures sludge levels. 
            Its reliability and convenience make it a valuable tool in various industrial and municipal settings.<br/>
          </p>
        </div>
        <div className={style.colright}>
          <div className={style.aboutspec} data-aos="fade-left">
            <h3 className='flex items-center' data-aos="fade-left">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
              </svg>
              ENGINEERED WITH PASSION
            </h3>
            <p>With attention to detail and focus on function, the end result is a premiere product</p>
          </div>
          <div className={style.aboutspec}  data-aos="fade-left">
            <h3 className='flex items-center'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
              </svg>
              LIGHTNING FAST
            </h3>
            <p>Accurate sludge blanket levels with the touch of a button</p>
          </div>
          <div className={style.aboutspec}  data-aos="fade-left">
            <h3 className='flex items-center'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
              </svg>
              CONTINUED SUPPORT
            </h3>
            
            <p>Spare parts and customizable options available</p>
          </div>
        </div>
      </div>

      <Footer />
      
    </div>
  );
}

export default Home
import React, { useState } from 'react';
import style from '../styles/Products.module.scss'
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';

const gallery = [
  "unit.jpg",
  "probe-and-reciever.jpg",
  "sludge-probe-photocell-detector.jpg",
  "sludge-probe-top-down-full-view.jpg"
]

function Products() {
  const [mainImage, setMainImage] = useState(0);

  return (
    <div>
      <Navbar />

      <div className={style.titlebar}>
        <h2>Products</h2>
      </div>

      <div className={style.maincontent}>
        <div className={style.gallery}>
          <img 
            src={require("./images/" + gallery[mainImage])} 
            alt="main display" 
            className={style.mainimage}
            layout="responsive"
          />
          <div className={style.minigallery}>
            <img 
              src={require("./images/" + gallery[0])} 
              alt="full view of sludge probe with cable coiled around detector and receiver" 
              onMouseEnter={()=>setMainImage(0)} 
              className={style.minipic}
            />
            <img 
              src={require("./images/" + gallery[1])} 
              alt="full view of sludglite with cable, detector, and receiver" 
              onMouseEnter={()=>setMainImage(1)} 
              className={style.minipic}
            />
            <img 
              src={require("./images/" + gallery[2])} 
              alt="reciever and probe next to each other" 
              onMouseEnter={()=>setMainImage(2)} 
              className={style.minipic}
            />
            <img 
              src={require("./images/" + gallery[3])} 
              alt="armored sludglite receiver closeup, photoeye, photosensor, stainless body" 
              onMouseEnter={()=>setMainImage(3)} 
              className={style.minipic}
            />
          </div>
        </div>

        <div className={style.shopblock}>
          <div className='mb-6 sm:mb-0'>
            <h4>Ecolotech Armored Sludglite</h4>
            <h3>Portable Sludge Blanket Level Detector</h3>
          </div>
          <div className={style.shopselection}>
            <div className={style.dropdown}>
              <p className={style.prod}>Sludge blanket level detector</p>
              <p className={style.price}>$877.00</p>
            </div>
            <div className={style.textblock}>
              <h4>Product Details</h4>
              <ul>
                <li>Receiver and probe are both constructed from corrosion resistant stainless steel</li>
                <li>Probe is completely o-ring sealed and is triple secured to the reinforced Neoprene cable</li>
                <li>Operates on a photo-optic sensing principal</li>
                <li>Producing a variable audio digital frequency output as the sludge level is reached</li>
                <li>Audio output receiver is completely solid state and is equipped with 30 feet of cord, a speaker, push button switch, 9-volt battery and nylon lanyard</li>
              </ul>
              <button className={style.btncontainer}>
                Contact Us to Order Now
              </button>
            </div>
          </div>
          <div>
            <a className={style.pdflink} target={"_blank"} rel='noreferrer' href={require("./images/operation-and-maintenance-manual.pdf")}>
              <img className={style.pdficon} src={require("./images/pdf-icon.png")} alt='pdf-icon'></img>Operation Manual
            </a>
          </div>
        </div>
      </div>

      <Footer />
    
    </div>
  )
}

export default Products
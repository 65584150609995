import React, { useState, useEffect } from 'react';
import style from '../../styles/Navbar.module.scss';
import { Link } from 'react-router-dom';

function Navbar() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getScrolled = () => {
    return scrollPosition > 100 ? style.scrolled : "";
  }

  return (
    <>
      <div className={`${style.navbar} ${getScrolled()}`}>
        <div className={style.links}>
          <Link to="/" className={style.link}>Home</Link>
          <Link to="/products" className={style.link}>Products</Link>
          <Link to="/contact" className={style.link}>Contact</Link>
        </div>
      </div>

      <div className={style.hamburger}>
        <svg onClick={() => setDrawerOpen(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-slate-500 w-8 h-8">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5" />
        </svg>
      </div>

      <div className={style.drawer + ` ${drawerOpen ? 'visible' : 'invisible'}`}>
        <div className={style.opacityMask + ` ${drawerOpen ? 'opacity-100' : 'opacity-0'}`}></div>
        <div className={style.window + ` ${drawerOpen ? style.draweropened : ''}`} onClick={() => setDrawerOpen(false)} >
          <div className={style.closeButton}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" color='text-gray-400' className="text-slate-500 w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
          </div>
          <ul>
            <Link onClick={() => setDrawerOpen(false)} to="/" className={style.link}>Home</Link>
            <Link onClick={() => setDrawerOpen(false)} to="/products" className={style.link}>Products</Link>
            <Link onClick={() => setDrawerOpen(false)} to="/contact" className={style.link}>Contact</Link>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar